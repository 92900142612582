<template>
  <div
    class="content"
    :style="{
      'border-left': getBorderSetting(this.environment, this.clientsLoading),
    }"
  >
    <create-client-dialog />
    <edit-client-dialog />
    <domains-dialog />
    <switch-branch-modal
      v-if="project"
      :project="project"
      @close="closeModal"
      @submitProject="submitProject"
    />
    <UpdatePlatformModalVue
      v-if="updatePlatformProject"
      :project="updatePlatformProject"
      @close="closeModal"
      @updateProject="updateProject"
    />
    <v-card
      dark
      tile
      :class="{ warn: showProdWarning, main: !showProdWarning }"
    >
      <v-card-title style="" class="d-flex justify-center main__title">
        <span class="toolbar"
          >{{ prettifiedRegion }} - {{ prettifiedEnv }}</span
        >
        <v-spacer />
        <v-spacer />

        <v-text-field
          v-model="search"
          :options="options"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          :class="{ 'width-full': $vuetify.breakpoint.smAndDown }"
        />
        <v-spacer />
        <v-spacer />
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              class="fab toolbar"
              outlined
              fab
              small
              v-on="on"
              @click="showOnlyFavorites = !showOnlyFavorites"
            >
              <v-icon>
                {{
                  showOnlyFavorites ? 'mdi-star' : 'mdi-star-outline'
                }}</v-icon
              >
            </v-btn>
          </template>
          <span>{{ favoritesIconMsg }}</span>
        </v-tooltip>
        <v-btn class="fab toolbar" outlined fab small @click="addClient()">
          <v-icon color="#fff">mdi-plus</v-icon>
        </v-btn>
        <v-btn class="fab toolbar" outlined fab small @click="exportClients">
          <v-icon color="#fff">mdi-download</v-icon>
        </v-btn>
        <v-btn class="fab toolbar" outlined fab small @click="getClients">
          <v-icon color="#fff">mdi-refresh</v-icon>
        </v-btn>
      </v-card-title>
    </v-card>

    <v-data-table
      :headers="headers"
      :search="search"
      :items="filteredClients"
      :loading="clientsLoading"
      :items-per-page="15"
      :custom-sort="customSort"
    >
      <template #[`item.favorite`]="{ item }">
        <v-btn
          small
          color="orange"
          icon
          @click="toggleFavoriteState(item.token)"
        >
          <v-icon>
            {{ isFavorite(item.token) ? 'mdi-star' : 'mdi-star-outline' }}
          </v-icon>
        </v-btn>
      </template>

      <template #[`item.name`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <span
              :style="{
                'font-weight': 'bold',
                color: getVersionColor(item.platformInfo.updatedAt),
              }"
              v-on="on"
              >{{ item.name }}</span
            >
          </template>
          <span>{{ getVersionTooltip(item.platformInfo.updatedAt) }}</span>
        </v-tooltip>
      </template>

      <template #[`item.misc.msActive`]="{ item }">
        <v-btn v-if="item.misc.msActive" icon @click="disableClient(item)">
          <v-icon color="#08b000">mdi-check</v-icon>
        </v-btn>
        <v-btn
          v-else-if="!item.misc.msActive && !isTasksPlatform(item)"
          icon
          @click="enableClient(item)"
        >
          <v-icon color="#ff0000">mdi-minus</v-icon>
        </v-btn>
      </template>

      <template #[`item.domains`]="{ item }">
        <a
          style="color: #2680c3"
          :href="'https://' + getMainFQDN(item)"
          target="_blank"
          ><b>{{ getMainFQDN(item) }}</b></a
        >
        <span
          v-if="item.domains.length > 1"
          :id="`item-urls-${item.token}`"
          class="item-urls"
        >
          and
          <span class="dotted-span" @click="editDomains(item.token)"
            >{{ item.domains.length - 1 }} more</span
          >
        </span>
      </template>

      <template #[`item.platformInfo.branch`]="{ item }">
        <samp>
          <a
            :href="`https://github.com/SkipsoLabs/platform/tree/${item.platformInfo.branch}`"
            target="_blank"
            >{{ item.platformInfo.branch }}</a
          ></samp
        >
      </template>

      <template #[`item.platformInfo.version`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <samp v-on="on"> {{ item.platformInfo.version.substring(1) }}</samp>
          </template>
          <span>Pushed at: {{ formatDate(item.platformInfo.updatedAt) }}</span>
        </v-tooltip>
      </template>

      <template #[`item.stylesInfo.branch`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <a
              :href="`https://github.com/SkipsoLabs/clients/tree/${item.stylesInfo.branch}`"
              target="_blank"
            >
              <samp v-on="on">
                {{ item.stylesInfo.branch }}
              </samp>
            </a>
          </template>
          <span>Pushed at: {{ formatDate(item.stylesInfo.updatedAt) }}</span>
        </v-tooltip>
      </template>

      <template #[`item.updatePlatform`]="{ item }">
        <v-btn icon @click="initPlatformUpdate(item)">
          <v-icon :color="getVersionColor(item.platformInfo.updatedAt)"
            >mdi-update</v-icon
          >
        </v-btn>
      </template>

      <template #[`item.switchBranch`]="{ item }">
        <v-btn icon @click="runSwitchBranch(item)">
          <v-icon>mdi-shuffle-variant</v-icon>
        </v-btn>
      </template>

      <template #[`item.editClient`]="{ item }">
        <v-btn icon @click="editClient(item.token)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>

      <template #[`item.editDomains`]="{ item }">
        <v-btn icon @click="editDomains(item.token)">
          <v-icon>mdi-web</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import compareVersions from 'compare-versions';
import EventBus from '../event-bus';
import { regions, environments } from '../store/actions/auth/pretty-data';
import SwitchBranchModal from '@/components/Clients/Modals/SwitchBranchModal.vue';
import UpdatePlatformModalVue from '../components/Clients/Modals/UpdatePlatformModal.vue';

import {
  CreateClientDialog,
  EditClientDialog,
  DomainsDialog,
} from './Clients/index.js';

export default {
  name: 'ClientsTable',
  // Inner components
  components: {
    CreateClientDialog,
    EditClientDialog,
    DomainsDialog,
    SwitchBranchModal,
    UpdatePlatformModalVue,
  },
  // Component data
  data() {
    return {
      showOnlyFavorites: false,
      switchingBranches: false,
      project: null,
      updatePlatformProject: null,
      selectedBranch: '',
      favoriteList: [],
      search: '',
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'favorite',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Int. name',
          align: 'start',
          sortable: true,
          value: 'internalName',
        },
        {
          text: 'Domains',
          align: 'start',
          sortable: false,
          value: 'domains',
        },

        {
          text: 'Branch',
          align: 'start',
          sortable: true,
          value: `platformInfo.branch`,
        },
        {
          text: 'Version',
          align: 'start',
          sortable: true,
          value: `platformInfo.version`,
        },
        {
          text: 'Style Bundle',
          align: 'start',
          sortable: true,
          value: `bundleName`,
        },
        {
          text: 'Style Branch',
          align: 'start',
          sortable: true,
          value: `stylesInfo.branch`,
        },
        {
          text: 'Domain settings',
          value: 'editDomains',
          sortable: false,
        },
        {
          text: 'Update',
          align: 'center',
          sortable: false,
          value: 'updatePlatform',
        },
        {
          text: 'Switch branch',
          align: 'center',
          sortable: false,
          value: 'switchBranch',
        },
        {
          value: 'editClient',
          sortable: false,
        },
      ],
      options: {},
    };
  },

  // Computed props
  computed: {
    ...mapGetters([
      'clients',
      'clientsLoading',
      'isAuthenticated',
      'region',
      'environment',
    ]),

    showProdWarning() {
      return this.environment == 'prod' && this.clientsLoading === false;
    },

    prettifiedRegion() {
      return regions[this.region];
    },

    prettifiedEnv() {
      return environments[this.environment];
    },
    // if showOnlyFavorites is true, filter the clients list and return only the favorites
    // else return the clients list and order it by favorites first
    filteredClients() {
      return this.showOnlyFavorites
        ? this.clients.filter((item) => this.favoriteList.includes(item.token))
        : this.mapOrder(this.clients, this.favoriteList, 'token');
    },
    // Tooltip msg for favorite button
    favoritesIconMsg() {
      return this.showOnlyFavorites
        ? 'Show all platforms'
        : 'Show only favorite platforms';
    },
  },

  mounted() {
    // Get favorite list from local storage
    if (localStorage.getItem('favoriteList')) {
      try {
        this.favoriteList = JSON.parse(localStorage.getItem('favoriteList'));
      } catch (e) {
        localStorage.removeItem('favoriteList');
      }
    }
  },

  // On component creation
  created() {
    // Refresh data every 2 minutes
    setInterval(() => {
      this.$store.state.autoRefresh = true;
      this.getClients();
    }, 120000);
  },

  // Methods
  methods: {
    ...mapActions([
      'getClients',
      'updateClient',
      'updatePlatform',
      'switchBranch',
      'exportClients',
    ]),

    ...mapMutations([
      'showClientCreationDialog',
      'showClientEditingDialog',
      'showDomainsDialog',
    ]),
    updateProject(val) {
      this.updatePlatformProject.project = val;
      this.updatePlatform(this.updatePlatformProject);
    },
    submitProject(val) {
      this.project.selection = val;
      this.switchBranch(this.project);
    },
    closeModal() {
      this.updatePlatformProject = null;
      this.project = null;
    },
    // Order an array of objects based on another array order
    mapOrder(array, order, key) {
      array.sort((a, b) => {
        const A = a[key];
        const B = b[key];

        if (order.indexOf(A) < order.indexOf(B)) {
          return 1;
        }
        return -1;
      });

      return array;
    },

    // Check if an item is in the favorite list
    isFavorite(token) {
      return this.favoriteList.includes(token);
    },

    // Add or remove an item from the favorite list
    toggleFavoriteState(token) {
      const index = this.favoriteList.indexOf(token);
      // check if the item.token is in the favorite list
      if (this.isFavorite(token)) {
        this.favoriteList.splice(index, 1);
      } else {
        this.favoriteList.push(token);
      }

      this.saveFavorite();
    },

    // Save the favorite list in local storage
    saveFavorite() {
      const parsed = JSON.stringify(this.favoriteList);
      localStorage.setItem('favoriteList', parsed);
    },

    getKey(obj, key) {
      let value = obj;
      const keys = key.split('.');
      // eslint-disable-next-line no-restricted-syntax
      for (key of keys) {
        value = value[key];
        if (!value) {
          return undefined;
        }
      }
      return value;
    },

    // Allow proper version sorting
    customSort(items, index, isDesc) {
      if (index && index[0]) {
        const column = index[0];

        items.sort((a, b) => {
          if (column === 'platformInfo.version') {
            const lval = this.getKey(a, column);
            const rval = this.getKey(b, column);

            // eslint-disable-next-line no-nested-ternary
            return isDesc[0]
              ? compareVersions(lval, rval) === 1
                ? -1
                : 1
              : compareVersions(lval, rval);
          }

          const lval = this.getKey(a, column);
          const rval = this.getKey(b, column);
          const check = lval.toLowerCase() > rval.toLowerCase();

          if (isDesc[0]) {
            return check ? -1 : 1;
          }

          return check ? 1 : -1;
        });
      }
      return items;
    },

    addClient() {
      this.showClientCreationDialog(true);
    },

    editClient(token) {
      EventBus.$emit('toggleEdit', token);
      this.showClientEditingDialog(true);
    },

    editDomains(token) {
      EventBus.$emit('toggleEdit', token);
      this.showDomainsDialog(true);
    },

    formatDate(timestamp) {
      const localizedFormat = require('dayjs/plugin/localizedFormat');
      dayjs.extend(localizedFormat);

      return `${dayjs.unix(timestamp).format('lll')}`;
    },

    getBorderSetting(env, isLoading) {
      const color = {
        prod: '#aB2330',
        demo: '#fb8c00',
        staging: '#4caf50',
      };

      return isLoading ? 'none' : `5px solid ${color[env]}`;
    },

    getVersionColor(timestamp) {
      const dateObj = dayjs.unix(timestamp);

      if (dateObj.isBefore(dayjs().subtract(15, 'days'))) {
        return '#D32F2F';
      }

      if (dateObj.isBefore(dayjs().subtract(10, 'days'))) {
        return '#F57F17';
      }
    },

    getVersionTooltip(timestamp) {
      const dateObj = dayjs.unix(timestamp);

      if (dateObj.isBefore(dayjs().subtract(15, 'days'))) {
        return 'Version older than 15 days!';
      }

      if (dateObj.isBefore(dayjs().subtract(10, 'days'))) {
        return 'Version older than 10 days.';
      }

      return 'Updated in the last 10 days';
    },

    async initPlatformUpdate(item) {
      this.updatePlatformProject = item;
    },

    async runSwitchBranch(item) {
      this.project = item;
    },

    enableClient(item) {
      item.misc.msActive = true;
      const client = { ...item };
      client.misc.msActive = true;

      this.updateClient(client);
    },

    disableClient(item) {
      item.misc.msActive = false;
      const client = { ...item };
      client.misc.msActive = false;

      this.updateClient(client);
    },

    isTasksPlatform(item) {
      return item.internalName.includes('sk-internal-tasks');
    },

    getMainFQDN(item) {
      if (item.domains.length > 1) {
        return item.domains.slice(-1)[0];
      }

      return item.domains[0];
    },
  },
};
</script>

<style lang="scss">
.sk-modal {
  transition: 0s;
  height: auto;
  height: 100%;
  width: 100%;
  position: fixed;
  background: none;
  z-index: 10;
  &-dialog {
    padding: 0;
  }
}
.sk-modal-content {
  position: relative;
  min-width: 375px;
  min-height: 420px;
  overflow-y: auto;
  .modal-header {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
}
.sk-modal-body {
  padding-bottom: 0;
}
.fab {
  margin-left: 10px;
}

.dotted-span {
  border-bottom: 1px dotted #111;
  cursor: pointer;
}

.item-urls {
  position: relative !important;
}

.main {
  background: #0f5384 !important;
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 18px 16px 16px;
    width: 100%;
  }
}

.toolbar {
  margin-top: 2vh;
}

.warn {
  background-color: #ab2330 !important;
}

.swal2-select {
  border-style: solid;
  border-radius: 0.25em;
}

.swal2-deny {
  position: absolute;
  bottom: 10px;
}

.switch-master-btn {
  height: 33px;
}

.content {
  height: 100vh;
}

.content {
  table {
    position: relative;
    display: block;
    height: calc(100vh - 61px);
    width: 100%;
    overflow-y: auto;
    padding-bottom: 60px;
    thead {
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 1;
    }
    colgroup {
      width: 100vw;
    }
  }
  .v-data-table-header-mobile {
    tr {
      th {
        width: 100%;
      }
    }
  }
  .v-data-footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-color: white;
  }
}

.pop-enter-active,
.pop-leave-active {
  transition: opacity 0s ease;
}
</style>